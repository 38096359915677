import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../../images/resslideimages/rs1 (1).jpg'
import img2 from '../../../images/resslideimages/rs1 (2).jpg'
import img3 from '../../../images/resslideimages/rs1 (3).jpg'
import img4 from '../../../images/resslideimages/rs1 (4).jpg'
import img5 from '../../../images/resslideimages/rs1 (5).jpg'
import img6 from '../../../images/resslideimages/rs1 (6).jpg'
import img7 from '../../../images/resslideimages/rs1 (7).jpg'
import styled from 'styled-components'

const slide2 = () => {
  return (

    <CWrap>
    <Carousel showThumbs={false} autoPlay infiniteLoop interval={5000} transitionTime={550} stopOnHover={false}  width="100%">
     
    <div>
        <img src={img7} width="100%" />
    </div>
    <div>
        <img src={img2} width="100%" />
    </div>
    <div>
        <img src={img3}  width="100%"/>
    </div>

    <div>
        <img src={img4} width="100%" />
    </div>
    <div>
        <img src={img5} width="100%" />
    </div>
    <div>
        <img src={img6}  width="100%"/>
    </div>

    <div>
        <img src={img1}  width="100%"/>
    </div>
   
</Carousel>
 </CWrap>
  );
};


const CWrap = styled.div`

.carousel .slider-wrapper{

height: 100% !important;

}


.carousel .slider img{

    height:150vh ;
    overflow:hidden;
}

@media(max-width:500px){

    .carousel .slider img{

    height: 40vh !important;
}
    
}

@media(max-width:960px){

.carousel .slide img{

height: 70vh ;
}
}
`
export default slide2;