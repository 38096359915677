import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout";
import { ParallaxProvider } from 'react-scroll-parallax';
import img1 from '../images/kitchen/1.jpg'
import img2 from '../images/bedroom/1.jpg'
import img3 from '../images/crockery/1.jpg'
import img4 from '../images/TV units/1.jpg'
import img5 from '../images/wardrobe/15.jpg'
import img6 from '../images/shoe rack - Foyer/1.jpg'
import img7 from '../images/dining/6.jpg'
import img8 from '../images/console Foyer Area/4.jpg'
import img9 from '../images/leavinArea/9.jpg'


import Loadable from 'react-loadable';
import Slide from '../components/NewCOmponents/slider2/slide2'
import  {kdata, bdata, cdata, tdata,ldata, sdata, ddata, wdata} from '../data/housegdata'
const HoseSec = Loadable({
  loader: () => import('../components/NewCOmponents/housesec/HouseSec'),
  loading: "loading........",
});

const MobHouse = Loadable({
  loader: () => import('../components/NewCOmponents/housesec/mobhouseSec'),
  loading: "loading........",
});



let Hdata = [{

  src:img1,
  title:"This is the Contents",
  content:"The heart of any home, a well-designed kitchen makes life more comfortable and pleasant for the users and occupants. ",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  amount:0.3,
  number:"Kitchen",
  path:"/kitchen",
  gallery:kdata


},
{

  src:img2,
  title:"This is the Contents",
  content:"The design of a bedroom must be intimate and should ensure that the occupant feels relaxed and comfortable in their private haven. ",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  order:"order-sm-2",
  amount:0.2,
  number:"Bedroom",
  path:"/bedroom/",

  gallery:bdata



},
{

  src:img3,
  title:"This is the Contents",
  content:"All that beautiful china needs a perfect storage solution, doesn't it? A simple and utilitarian crockery unit is the answer for all your storage problems",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  amount:0.3,
  number:"Crockery",
  path:"/crockery",

  gallery:cdata


},
{

  src:img4,
  title:"This is the Contents",
  content:"From minimal tv units, to elaborate multipurpose ones, we make sure that your TV unit is everything you want it to be. ",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  order:"order-sm-2",
  amount:0.3,
  number:"TV units",
  path:"/tvunit",

  gallery:tdata


},
{

  src:img5,
  title:"This is the Contents",
  content:"We make sure that your wardrobe is designed to suit your preference to perfection. A well-designed wardrobe is the perfect addition to your bedroom. ",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  amount:0.3,
  number:"Wardrobe",
  path:"/wardrobe",

  gallery:wdata


},
{

  src:img6,
  title:"This is the Contents",
  content:"We as a design studio believe that every part of the home needs equal attention. Choose a suitable shoerack to do away with your storage woes. ",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  order:"order-sm-2",
  amount:0.3,
  number:"Shoe rack ",
  path:"/Shoerack",

  gallery:sdata


},
{

  src:img7,
  title:"This is the Contents",
  content:"The fondest memories are made when gathered around the table",
  sec1:"col-sm-7",
  sec2:"col-sm-5",

  amount:0.1,
  number:"Dining ",
  path:"/dining",

  gallery:ddata


},
{

  src:img9,
  title:"This is the Contents",
  content:"The living room should be a place where we feel totally at ease - temple of the soul",
  sec1:"col-sm-7",
  sec2:"col-sm-5",
  order:"order-sm-2",

  amount:0.2,
  number:"Living Area",
  path:"/living_area",

  gallery:ldata


},
{

  src:img8,
  title:"This is the Contents",
  content:"In your home, let love abide and bless all those who step inside, lets start with a beautiful foyer entryway",
  sec1:"col-sm-7",
  sec2:"col-sm-5",

  amount:0.1,
  number:"Foyer Area ",
  path:"/foyer_area",

  gallery:ddata


}
]

const ResidentialPage = () => (


    <Layout>
      <SEO title="Residential" />

 <Slide/>
 <ParallaxProvider>
   
   <HoseSec hdata={Hdata}/>
   
<MobHouse mdata = {Hdata}/>
</ParallaxProvider>
    </Layout>
  )
  
  
  
  export default ResidentialPage